import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./Tools.scss"
import * as styles from "./Tools.module.scss"

const data = [
  {
    title: "Test Automation Tools",
    tech: [
      {
        name: "Selenium",
        icon: "https://invozone-backend.s3.amazonaws.com/selenium_e7216101c3.svg",
      },
      {
        name: "Cypress",
        icon: "https://invozone-backend.s3.amazonaws.com/cypress_d89f4278f9.svg",
      },
      {
        name: "Playwright",
        icon: "https://invozone-backend.s3.amazonaws.com/playwright_0d00b0a341.svg",
      },
      {
        name: "Test.io",
        icon: "https://invozone-backend.s3.amazonaws.com/test_io_2295b34de6.svg",
      },
      {
        name: "Cucumber",
        icon: "https://invozone-backend.s3.amazonaws.com/cucumber_2f2067a2f4.svg",
      },
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.amazonaws.com/postman_f1788df09e.svg",
      },
      {
        name: "Browserstack",
        icon: "https://invozone-backend.s3.amazonaws.com/browserstack_8f4893d150.svg",
      },
      {
        name: "Ci/Cd",
        icon: "https://invozone-backend.s3.amazonaws.com/cicd_67916e961b.svg",
      },
    ],
  },
  {
    title: "Test or Defect Management Tools",
    tech: [
      {
        name: "Jira",
        icon: "https://invozone-backend.s3.amazonaws.com/jira_9661a9659b.svg",
      },
      {
        name: "Trello",
        icon: "https://invozone-backend.s3.amazonaws.com/trello_0ec2a66fc4.svg",
      },
      {
        name: "Asana",
        icon: "https://invozone-backend.s3.amazonaws.com/asana_2a70d326a5.svg",
      },
      {
        name: "Bugzilla",
        icon: "https://invozone-backend.s3.amazonaws.com/bugzilla_8b2affb5bd.svg",
      },
      {
        name: "OpenProject",
        icon: "https://invozone-backend.s3.amazonaws.com/open_project_87ed92e367.svg",
      },
      {
        name: "Testrail",
        icon: "https://invozone-backend.s3.amazonaws.com/testrail_0254622859.svg",
      },
      {
        name: "Clickup",
        icon: "https://invozone-backend.s3.amazonaws.com/click_up_99e982bfcb.svg",
      },
    ],
  },
  {
    title: "Documentation tools",
    tech: [
      {
        name: "Notion",
        icon: "https://invozone-backend.s3.amazonaws.com/notion_51d3c84a26.svg",
      },
      {
        name: "Jira-Confluence",
        icon: "https://invozone-backend.s3.amazonaws.com/jira_confluence_bbd6eb4e8d.svg",
      },
    ],
  },
  {
    title: "Manual Tools",
    tech: [
      {
        name: "Excel",
        icon: "https://invozone-backend.s3.amazonaws.com/excel_67d450e85f.svg",
      },
      {
        name: "Word",
        icon: "https://invozone-backend.s3.amazonaws.com/word_0ce7882490.svg",
      },
      {
        name: "Apache Jmeter",
        icon: "https://invozone-backend.s3.amazonaws.com/apache_109a896795.svg",
      },
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.amazonaws.com/postman_f1788df09e.svg",
      },
      {
        name: "Swagger",
        icon: "https://invozone-backend.s3.amazonaws.com/swagger_23c350012e.svg",
      },
    ],
  },
]

const Tools = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="powerPackedSQA">
      <div className={styles.powerPackedSQATechStacks}>
        <Container>
          <p
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />
          <h2
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`powerPackedSQA`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockcustom">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                    width={100}
                                    height={60}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default Tools
