import React, { useState } from "react"

const StringTruncate = ({ str, length }) => {
  const [isTruncated, setIsTruncated] = useState(true)

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated)
  }

  if (!str || str.length <= length) {
    return <span>{str}</span>
  }

  return (
    <span>
      {isTruncated ? str.substring(0, length) + `...` : str}
      <br/>
      <button
        onClick={toggleTruncate}
        style={{
          color: "#2276FF",
          cursor: "pointer",
          background: "none",
          border: "none",
          padding: "14px 0 0",
        }}
      >
        {isTruncated ? `See More` : `See Less`}
      </button>
    </span>
  )
}

export default StringTruncate
