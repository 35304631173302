import React, { useContext } from "react"
import AccordionContext from "react-bootstrap/AccordionContext"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Accordion from "react-bootstrap/esm/Accordion"
import { useAccordionToggle } from "react-bootstrap/esm/AccordionToggle"
import Row from "react-bootstrap/Row"
import minus from "../../images/Healthcare/minus.svg"
import plus from "../../images/Healthcare/plus.svg"
import * as styles from "./TestingServices.module.scss"
import "./TestingServices.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
          style={{ width: "90%" }}
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/minus_726ad420c9.svg"
              }
              decoding="async"
              loading="lazy"
              className={styles.quesColorSelected}
              alt="icon"
              width={48}
              height={48}
            />
          ) : (
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/plus_6f4970dc6e.svg"
              }
              decoding="async"
              loading="lazy"
              className={styles.quesColor}
              alt="icon"
              width={48}
              height={48}
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const TestingServices = ({ strapiData }) => {
  return (
    <div className={styles.testingServices}>
      <Container>
        <p className={styles.title}>{strapiData?.title}</p>
        <h2 className={styles.subTitle}>{strapiData?.subTitle}</h2>

        <Accordion>
          <Row className="justify-content-center gap-30">
            <Col lg={6}>
              <div className="sqaAccordianRevamp">
                {strapiData?.cards &&
                  strapiData?.cards?.map(
                    (e, i) =>
                      i < 4 && (
                        <Card key={i} className={styles.cards}>
                          <ContextAwareToggle eventKey={`ek-${i}`}>
                            <div className={styles.accImg}>
                              <h3 className="mb-0" type="button">
                                {e?.title}
                              </h3>
                            </div>
                          </ContextAwareToggle>
                          <Accordion.Collapse eventKey={`ek-${i}`}>
                            <Card.Body className={styles.textColor}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                  )}
              </div>
            </Col>
            <Col lg={6} className={styles.nextGenRight}>
              <div className="sqaAccordianRevamp">
                {strapiData?.cards &&
                  strapiData?.cards?.map(
                    (e, i) =>
                      i > 3 && (
                        <Card key={i}>
                          <ContextAwareToggle eventKey={`ek-${i}`}>
                            <div className={styles.accImg}>
                              <h3 className="mb-0" type="button">
                                {e?.title}
                              </h3>
                            </div>
                          </ContextAwareToggle>
                          <Accordion.Collapse eventKey={`ek-${i}`}>
                            <Card.Body className={styles.textColor}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                  )}
              </div>
            </Col>
          </Row>
        </Accordion>
      </Container>
    </div>
  )
}

export default TestingServices
