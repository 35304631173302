import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.banner}>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <Container>
            <div className={styles.dedicatedHeading}>
              <p
                className={styles.subTitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
              ></p>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
            </div>
            <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
              {strapiData?.buttons[0]?.title}
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                decoding="async"
                loading="lazy"
                alt="explore icon"
              />
            </Link>
            <div className={styles.sqaProject}>
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/appwork_f1095e099c.png"
                decoding="async"
                loading="lazy"
                alt="app work"
                width={135}
                height={52}
              />
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/column_ee4655a778.png"
                decoding="async"
                loading="lazy"
                alt="column"
                width={156}
                height={33}
              />
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/fresh_prep_logo_9bd992291b.png"
                decoding="async"
                loading="lazy"
                alt="freshprep"
                width={133}
                height={33}
              />
            </div>
          </Container>
        </div>
      </Container>
    </div>
  )
}

export default Banner
