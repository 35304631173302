// extracted by mini-css-extract-plugin
export var ClientsDataPortfoliioRevamp = "Testimonials-module--ClientsDataPortfoliioRevamp--88a47";
export var SliderWidth = "Testimonials-module--SliderWidth--8b76b";
export var Trust = "Testimonials-module--Trust--3cb07";
export var bannerHeadingPortfolioRevamp = "Testimonials-module--bannerHeadingPortfolioRevamp--8fd62";
export var buton = "Testimonials-module--buton--48503";
export var devOpsRefineImages = "Testimonials-module--devOpsRefineImages--d8a7c";
export var disabled = "Testimonials-module--disabled--f2835";
export var heading = "Testimonials-module--heading--958cf";
export var icon = "Testimonials-module--icon--03dc6";
export var modalBodytestiRevamp = "Testimonials-module--modalBodytestiRevamp--91893";
export var modalContentTestiRevamp = "Testimonials-module--modalContentTestiRevamp--b8173";
export var overlay = "Testimonials-module--overlay--e102b";
export var overlay2 = "Testimonials-module--overlay2--7338d";
export var portfoliosLogos = "Testimonials-module--portfoliosLogos--a6776";
export var ser = "Testimonials-module--ser--848ef";
export var shieldTopBannerImg = "Testimonials-module--shieldTopBannerImg--d1756";
export var text = "Testimonials-module--text--4e93d";