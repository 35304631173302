// extracted by mini-css-extract-plugin
export var Android = "WhyChooseUs-module--Android--e3817";
export var arrowDiv = "WhyChooseUs-module--arrowDiv--6eece";
export var arrowImg = "WhyChooseUs-module--arrowImg--100c0";
export var boxContent = "WhyChooseUs-module--boxContent--13dff";
export var btn = "WhyChooseUs-module--btn--81e79";
export var cards = "WhyChooseUs-module--cards--91a4e";
export var cardsContent = "WhyChooseUs-module--cardsContent--51e09";
export var cardsZ = "WhyChooseUs-module--cardsZ--2914a";
export var djangoArrowImg = "WhyChooseUs-module--djangoArrowImg--30131";
export var estabBtn = "WhyChooseUs-module--estabBtn--5f4d2";
export var explorrBtn = "WhyChooseUs-module--explorrBtn--2abce";
export var finImg = "WhyChooseUs-module--finImg--398e8";
export var heading = "WhyChooseUs-module--heading--4f38e";
export var processSteps = "WhyChooseUs-module--processSteps--c39b4";
export var product1 = "WhyChooseUs-module--product1--cf097";
export var ser = "WhyChooseUs-module--ser--a5eec";
export var soft = "WhyChooseUs-module--soft--40262";
export var softwareTabs = "WhyChooseUs-module--softwareTabs--fe702";
export var tabImg = "WhyChooseUs-module--tabImg--5e9cf";
export var tabbingCards = "WhyChooseUs-module--tabbingCards--81755";