import React, { useState } from "react"
import * as styles from "./Testimonials.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"
import "./Testimonials.scss"
import Slider from "react-slick"

const Testimonials = ({ strapiData }) => {
  const data = [
    {
      name: "Oliver Wolff",
      role: "Kinde Product Manager",
      thumbnail:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007398_57cd07a579.webp",
      video:
        "https://www.youtube.com/embed/eGinGdGXdGA?si=RyFeh0vrfexzxpTU&autoplay=1",
    },
    {
      name: "Ryan Carter",
      role: "Shield Republic",
      thumbnail:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007397_70a98159dc.webp",
      video:
        "https://www.youtube.com/embed/ETvJcozwwzM?si=593q6eg9VhyVIm4B&autoplay=1",
    },
    {
      name: "Rizwan Hanif",
      role: "Xtransform’s CEO",
      thumbnail:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007399_1_1bb1ca592c.webp",
      video:
        "https://www.youtube.com/embed/SeBRvwbCuks?si=PBFkID4nfnforjxP&autoplay=1",
    },
  ]

  // const [activeStory, setActiveStory] = useState(data[0])
  // const [isModalOpen, setIsModalOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenSheild, setIsModalOpenSheild] = useState(false)
  const [isModalOpenStorageChain, setIsModalOpenStorageChain] = useState(false)

  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }
  const handleVideoIconClickSheild = () => {
    setIsModalOpenSheild(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const handleCloseModalSheild = () => {
    setIsModalOpenSheild(false)
  }
  const handleCloseModalStorageChain = () => {
    setIsModalOpenStorageChain(false)
  }

  // const handleVideoIconClick = () => {
  //   setIsModalOpen(true)
  // }

  // const handleCloseModal = () => {
  //   setIsModalOpen(false)
  // }

  const settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 2,
    // speed: 500,
    centerMode: true,
    centerPadding: "0px",
    // className : 'center',
    dots: true,
    dotsClass: "clients-dots",
    arrows: false,
    responsive: [
      // {
      //   breakpoint: 1280,
      //   settings: {
      //     slidesToShow: 2,
      //   },
      // },
      // {
      //   breakpoint: 1140,
      //   settings: {
      //     slidesToShow: 1,
      //   },
      // },

      {
        breakpoint: 991,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <p className={styles.ser}>{strapiData?.title}</p>
          <h2 className={styles.heading}>{strapiData?.subTitle}</h2>
          <Row className="align-items-center clientSlider">
            <Col lg={12} md={12} sm={12} xs={12}>
              <div
                // src="https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19488_dd4903f681.svg"
                className={styles.overlay}
                decoding="async"
                loading="lazy"
              />
              <div
                // src="https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19488_dd4903f681.svg"
                className={styles.overlay2}
                decoding="async"
                loading="lazy"
              />
            </Col>
            <Slider {...settings} className={styles.SliderWidth}>
              {data?.map((e, i) => (
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className={styles.shieldTopBannerImg}>
                    <img
                      className={styles.sheildImgTop}
                      src={e?.thumbnail}
                      alt={e?.name}
                      decoding="async"
                      loading="lazy"
                      style={{ width: "747px", height: "auto" }}
                      width={747}
                      height={365}
                    />
                    <button
                      href="#"
                      onClick={() => setIsModalOpenStorageChain(true)}
                      className={styles.buton}
                    >
                      <img
                        src={
                          "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_d99dfad8cf.png"
                        }
                        alt="play icon"
                        className={styles.icon}
                        decoding="async"
                        loading="lazy"
                        width={35}
                        height={22}
                        style={{ height: "22px", width: "35px" }}
                      />
                      <span className={styles.text}>Play Video</span>
                    </button>
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
      {data?.map((e, i) => (
        <Modal
          show={isModalOpenStorageChain}
          onHide={handleCloseModalStorageChain}
          keyboard={false}
          centered
          size="lg"
          contentClassName={styles.modalContentTestiRevamp}
        >
          <Modal.Body className={styles.modalBodytestiRevamp}>
            <iframe
              width="100%"
              height="315"
              src={e?.video}
              allowFullScreen
              title="YouTube video player"
              frameborder="0"
              allow="autoplay"
            />
          </Modal.Body>
        </Modal>
      ))}
    </div>
  )
}

export default Testimonials
