import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./QAPartner.module.scss"
import StringTruncate from "../../utils/stringTruncate"

const QAPartner = ({ strapiData }) => {
  return (
    <div className={styles.QAPartnerOuter}>
      <div className={styles.qaPartner}>
        <Container>
          <div>
            <p className={styles.title}>{strapiData?.title}</p>
            <h2 className={styles.subTitle}>{strapiData?.subTitle}</h2>
            <p className={styles.subTitle2}>{strapiData?.subTitle2}</p>
          </div>
          <Row>
            {strapiData?.cards?.map((v, i) => (
              <Col key={i} className={styles.qaCards} sm={12} lg={4}>
                <h3>{v?.title}</h3>
                <p>
                  <StringTruncate str={v?.subTitle} length={65} />
                </p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Container>
        <img
          src="https://invozone-backend.s3.us-east-1.amazonaws.com/qa_partner_1970205f19.webp"
          alt="QA Partner"
          loading="lazy"
          width={1280}
          height={564}
        />
      </Container>
    </div>
  )
}

export default QAPartner
