// extracted by mini-css-extract-plugin
export var cardBg = "Tools-module--cardBg--af19d";
export var cards = "Tools-module--cards--9ffe1";
export var cardsContent = "Tools-module--cardsContent--65f35";
export var description = "Tools-module--description--40245";
export var heading = "Tools-module--heading--69332";
export var iconssCard = "Tools-module--iconssCard--a7e8b";
export var nav = "Tools-module--nav--9b2d1";
export var navItem = "Tools-module--nav-item--7ce53";
export var navbarBlock = "Tools-module--navbarBlock--3fea7";
export var powerPackedSQATechStacks = "Tools-module--powerPackedSQATechStacks--84aac";
export var tabData = "Tools-module--tabData--5f261";
export var tech = "Tools-module--tech--4f2de";
export var techIcon = "Tools-module--techIcon--c1f44";
export var techImg = "Tools-module--techImg--bcfcb";
export var technologyIcon = "Tools-module--technologyIcon--de62c";