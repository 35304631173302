import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./ToolsMobile.scss"
import * as styles from "./ToolsMobile.module.scss"

const ToolsMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Test Automation Tools",
      tech: [
        {
          name: "Selenium",
          icon: "https://invozone-backend.s3.amazonaws.com/selenium_e7216101c3.svg",
        },
        {
          name: "Cypress",
          icon: "https://invozone-backend.s3.amazonaws.com/cypress_d89f4278f9.svg",
        },
        {
          name: "Playwright",
          icon: "https://invozone-backend.s3.amazonaws.com/playwright_0d00b0a341.svg",
        },
        {
          name: "Test.io",
          icon: "https://invozone-backend.s3.amazonaws.com/test_io_2295b34de6.svg",
        },
        {
          name: "Cucumber",
          icon: "https://invozone-backend.s3.amazonaws.com/cucumber_2f2067a2f4.svg",
        },
        {
          name: "Postman",
          icon: "https://invozone-backend.s3.amazonaws.com/postman_f1788df09e.svg",
        },
        {
          name: "Browserstack",
          icon: "https://invozone-backend.s3.amazonaws.com/browserstack_8f4893d150.svg",
        },
        {
          name: "Ci/Cd",
          icon: "https://invozone-backend.s3.amazonaws.com/cicd_67916e961b.svg",
        },
      ],
    },
    {
      title: "Test or Defect Management Tools",
      tech: [
        {
          name: "Jira",
          icon: "https://invozone-backend.s3.amazonaws.com/jira_9661a9659b.svg",
        },
        {
          name: "Trello",
          icon: "https://invozone-backend.s3.amazonaws.com/trello_0ec2a66fc4.svg",
        },
        {
          name: "Asana",
          icon: "https://invozone-backend.s3.amazonaws.com/asana_2a70d326a5.svg",
        },
        {
          name: "Bugzilla",
          icon: "https://invozone-backend.s3.amazonaws.com/bugzilla_8b2affb5bd.svg",
        },
        {
          name: "OpenProject",
          icon: "https://invozone-backend.s3.amazonaws.com/open_project_87ed92e367.svg",
        },
        {
          name: "Testrail",
          icon: "https://invozone-backend.s3.amazonaws.com/testrail_0254622859.svg",
        },
        {
          name: "Clickup",
          icon: "https://invozone-backend.s3.amazonaws.com/click_up_99e982bfcb.svg",
        },
      ],
    },
    {
      title: "Documentation tools",
      tech: [
        {
          name: "Notion",
          icon: "https://invozone-backend.s3.amazonaws.com/notion_51d3c84a26.svg",
        },
        {
          name: "Jira-Confluence",
          icon: "https://invozone-backend.s3.amazonaws.com/jira_confluence_bbd6eb4e8d.svg",
        },
      ],
    },
    {
      title: "Testing Tools",
      tech: [
        {
          name: "Excel",
          icon: "https://invozone-backend.s3.amazonaws.com/excel_67d450e85f.svg",
        },
        {
          name: "Word",
          icon: "https://invozone-backend.s3.amazonaws.com/word_0ce7882490.svg",
        },
        {
          name: "Apache Jmeter",
          icon: "https://invozone-backend.s3.amazonaws.com/apache_109a896795.svg",
        },
        {
          name: "Postman",
          icon: "https://invozone-backend.s3.amazonaws.com/postman_f1788df09e.svg",
        },
        {
          name: "Swagger",
          icon: "https://invozone-backend.s3.amazonaws.com/swagger_23c350012e.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="powerPackedMobileTech">
      <div className={styles.poweredSQAMobileTech}>
        <Container>
          <p
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />
          <h2
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="card-SQA" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`SqaAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2 || i === 4) &&
                          e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default ToolsMobile
