import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./ServiceType.module.scss"

const ServiceType = ({ strapiData, strapiData2 }) => {
  return (
    <div className={styles.serviceType}>
      <Container>
        <div>
          <p className={styles.title}>{strapiData?.title}</p>
          <h2 className={styles.subTitle}>{strapiData?.subTitle}</h2>
          <p className={styles.subTitle2}>{strapiData?.subTitle2}</p>
        </div>
        <div className={styles.data}>
          {strapiData?.cards?.map((v, i) => (
            <div key={i} className={styles.serviceCards}>
              <h3>{v?.title}</h3>
              <div>
                <p>{v?.subTitle}</p>
                <img
                  className={styles.woHover}
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_up_8d9f4428b6.svg"
                  alt=""
                  width={60}
                  height={60}
                />
                <img
                  className={styles.wHover}
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_98d2dec661.svg"
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.banner}>
          <h2>{strapiData2?.title}</h2>
          <Link to={strapiData2?.buttons[0]?.url} className={styles.btn}>
            {strapiData2?.buttons[0]?.title}
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
              decoding="async"
              loading="lazy"
              alt="explore icon"
            />
          </Link>
        </div>

      </Container>
    </div>
  )
}

export default ServiceType
