// extracted by mini-css-extract-plugin
export var cardBg = "ToolsMobile-module--cardBg--3301d";
export var cards = "ToolsMobile-module--cards--26ce9";
export var cardsContent = "ToolsMobile-module--cardsContent--b7ee5";
export var description = "ToolsMobile-module--description--257d1";
export var heading = "ToolsMobile-module--heading--49b41";
export var iconssCard = "ToolsMobile-module--iconssCard--1d257";
export var nav = "ToolsMobile-module--nav--043af";
export var navItem = "ToolsMobile-module--nav-item--f8cfb";
export var navbarBlock = "ToolsMobile-module--navbarBlock--aeeb1";
export var poweredSQAMobileTech = "ToolsMobile-module--poweredSQAMobileTech--4eee7";
export var tabData = "ToolsMobile-module--tabData--692dd";
export var tech = "ToolsMobile-module--tech--a2f4a";
export var techIcon = "ToolsMobile-module--techIcon--40aa0";
export var techImg = "ToolsMobile-module--techImg--2f658";
export var technologyIcon = "ToolsMobile-module--technologyIcon--de475";