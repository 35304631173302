// extracted by mini-css-extract-plugin
export var SliderWidth = "WorkPortfolio-module--SliderWidth--0855d";
export var Trust = "WorkPortfolio-module--Trust--8ef3c";
export var bottomLine = "WorkPortfolio-module--bottomLine--91a87";
export var btn = "WorkPortfolio-module--btn--20b3d";
export var btn2 = "WorkPortfolio-module--btn2--c076f";
export var clientImgs = "WorkPortfolio-module--clientImgs--de795";
export var content = "WorkPortfolio-module--content--0aee5";
export var devOpsRefineImages = "WorkPortfolio-module--devOpsRefineImages--b4533";
export var headSearch = "WorkPortfolio-module--headSearch--8c163";
export var heading = "WorkPortfolio-module--heading--870c1";
export var logoBtn = "WorkPortfolio-module--logoBtn--efaa8";
export var logos = "WorkPortfolio-module--logos--276e5";
export var overly = "WorkPortfolio-module--overly--1d46d";
export var portfolios = "WorkPortfolio-module--portfolios--4314d";
export var ser = "WorkPortfolio-module--ser--3044a";
export var talkBtn = "WorkPortfolio-module--talkBtn--ccba6";
export var talkBtn2 = "WorkPortfolio-module--talkBtn2--26dfb";
export var topLine = "WorkPortfolio-module--topLine--c6d1a";