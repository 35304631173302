import React from "react"
import Col from "react-bootstrap/esm/Col"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import StringTruncate from "../../utils/stringTruncate"
import * as styles from "./ApplicationType.module.scss"

const ApplicationType = ({ strapiData }) => {
  return (
    <div className={styles.applicationType}>
      <Container>
        <div>
          <p className={styles.title}>{strapiData?.title}</p>
          <h2 className={styles.subTitle}>{strapiData?.subTitle}</h2>
          <p className={styles.subTitle2}>{strapiData?.subTitle2}</p>
        </div>
        <Row className={styles.rowApp}>
          {strapiData?.cards?.map((v, i) => (
            <Col key={i} className={styles.applicationCards} sm={12} lg={4}>
              <h3>{v?.title}</h3>
              <p>
                <StringTruncate str={v?.subTitle} length={65} />
              </p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ApplicationType
